/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
// import './styles/app.scss';

// start the Stimulus application
import './bootstrap';
import { Workbox } from 'workbox-window';

const $ = require('jquery');
window.bootstrap = require('bootstrap');
global.$ = global.jQuery = $;
require('@popperjs/core');
require("select2");
require('jquery-ui-dist/jquery-ui.js');
require('dropify');
require('ionicons')
require('datatables.net');
require('jquery-validation');


$(document).ready(function() {
    $("a.endocontact-hrefold").on("click",function(e){

        $("form#redirect_old_endocontact").attr('action', $("form#redirect_old_endocontact").attr('action')+$(this).attr("href"));
        $("form#redirect_old_endocontact").submit();
        return false;
    });
});
$(document).ready(async function() {


    if ('serviceWorker' in navigator) {
        console.log('Le service worker peut être activé');
        // navigator.serviceWorker
        //     .register('/service-worker.js')
        //     .then(function() { console.log('Service Worker actif'); })
        //     .catch(function(error) { console.log('Le service worker a rencontré des problèmes.') });

        const isLoggedIn = await fetch('/api/logging').then(res => res.json());

        if ( isLoggedIn ) {

            const workbox = new Workbox('/service-worker.js', {scope: '/'});

            // workbox.addEventListener('installed', event => {
            //     if (event.isUpdate) {
            //         if ( confirm(`Une mise à jour des fonctionnalités hors ligne est disponible ! Sélectionnez OK pour l'appliquer. Attention, la page se rechargera.`) ) {
            //             window.location.reload();
            //         }
            //     }
            // });

            workbox.register()
                .then(() => {
                    console.log('Service Worker actif');
                    // setTimeout(() => {
                    //     fetch('/api/endoprotect/offline/url', {
                    //         redirect: 'error'
                    //     }).then(async (res) => {
                    //         if (res.status === 200) {
                    //             res.json().then(async (urls) => {
                    //                 const urlsToCache = urls.map((url) => {
                    //                     return {url, revision: null}
                    //                 });
                    //                 await workbox.messageSW({urlsToCache: urlsToCache});
                    //             })
                    //         }
                    //     }).catch((err) => console.error(err));
                    // }, 5 * 1000);
                })
                .catch((error) => console.log('Le service worker a rencontré des problèmes.\n' + error));

            // if (navigator.onLine) {
            //     setTimeout(async () => {
            //         let urls = await fetch('/api/endoprotect/offline/url', {
            //             redirect: 'error'
            //         }).then((res) => res.json()).catch((err) => console.error(err));
            //
            //         for (let url of urls) {
            //             caches.open('karlstorz-pages').then(cache => cache.add(url));
            //         }
            //     }, 5 * 1000);
            // }
        }
    }
});